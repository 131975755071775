<template>
  <div class="am-dealer-information">
    <div>
      <h3
        v-if="dealerTitle && smallHeadline"
        class="am-dealer-information__small-headline"
      >
        {{ dealerTitle }}
      </h3>
      <amui-headline
        v-else-if="dealerTitle"
        :text="dealerTitle"
        html-tag="h3"
        looks-like="h6"
      />
      <a
        :href="dealerMapsLink"
        v-if="dealerAddress && dealerMapsLink"
        target="_blank"
        rel="noopener"
        class="am-dealer-information__address"
      >
        <amui-icon name="location-on" />
        <span>{{ dealerAddress }}</span>
      </a>
    </div>
    <template v-if="rawDealerData">
      <div v-if="googleMaps.loaded" class="am-dealer-information__map-wrapper">
        <div ref="map" class="am-dealer-information__map"></div>
      </div>
      <div v-else class="am-dealer-information__map-privacy">
        <img
          src="https://cdn.automedia.de/widgets/vehicle-market/assets/various/map-preview.jpg"
          class="am-dealer-information__map-privacy-img"
        />
        <div class="am-dealer-information__map-privacy-content">
          <div>
            <amui-i18n
              :term="$t('maps.privacy.shortInfo.text')"
              tag="span"
              class="am-dealer-information__map-privacy-text"
            >
              <a
                slot="link1"
                href="https://policies.google.com/privacy?hl=de"
                target="_blank"
                rel="noopener"
                >{{ $t('maps.privacy.shortInfo.link1') }}</a
              >
              <a
                slot="link2"
                href="https://www.google.com/intl/de/help/terms_maps/"
                target="_blank"
                rel="noopener"
                >{{ $t('maps.privacy.shortInfo.link2') }}</a
              >
            </amui-i18n>
            <amui-button
              :label="$t('maps.privacy.showMap')"
              type="secondary"
              @click="onShowMap"
            />
          </div>
        </div>
      </div>
    </template>
    <div v-if="formattedPhoneNumber" class="am-dealer-information__contact">
      <div>{{ $t('dealerInformation.contact.label') }}</div>
      <a :href="'tel:' + phoneNumber">{{ formattedPhoneNumber }}</a>
    </div>

    <am-openinghours
      v-if="rawDealerData && rawDealerData.locations"
      :timezone="rawDealerData.timezone"
      :locations="rawDealerData.locations"
      class="am-dealer-information__openinghours"
    />
  </div>
</template>
<script>
import AmOpeninghours from '@/app/components/openinghours/openinghours.vue'
import { AmuiHeadline } from '@/../ui/components/headline'
import { AmuiButton } from '@/../ui/components/button'
import { AmuiIcon } from '@/../ui/components/icon'
import { AmuiI18n } from '@/../ui/components/i18n'
import { resolveCondition } from '@/../utils/condition-resolver.js'
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  components: {
    AmuiIcon,
    AmOpeninghours,
    AmuiI18n,
    AmuiHeadline,
    AmuiButton
  },
  props: {
    dealerId: {
      type: String,
      required: true
    },
    rawVehicleData: {
      // will be used for matching contact conditions related to specific vehicle properties
      type: Object,
      required: false,
      default: () => {}
    },
    smallHeadline: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapState('core', {
      settings: 'settings',
      googleMaps: 'googleMaps',
      appMode: 'appMode'
    }),
    ...mapGetters('core', {
      getDealerByMobileSellerId: 'getDealerByMobileSellerId'
    }),
    ...mapGetters('searchHybrid', ['getInitialSearchParams']),
    ...mapGetters('consent', { consentAllowedServices: 'allowedServices' }),
    rawDealerData() {
      return this.getDealerByMobileSellerId(this.dealerId)
    },
    dealerTitle() {
      let title = null

      if (this.rawDealerData?.name !== undefined) {
        title = this.rawDealerData.name
      }

      return title
    },
    dealerMapsLink() {
      let link = null

      if (this.rawDealerData?.vehicleMarket?.googlePlaceId !== undefined) {
        link =
          'https://www.google.com/maps/place/?q=place_id:' +
          this.rawDealerData.vehicleMarket.googlePlaceId
      } else if (
        this.rawDealerData?.name !== undefined &&
        this.rawDealerData?.address !== undefined
      ) {
        const d = this.rawDealerData.address
        const address =
          this.rawDealerData.name +
          ',' +
          d.street +
          ' ' +
          d.houseNumber +
          ', ' +
          d.zipcode +
          ' ' +
          d.city
        link =
          'https://www.google.com/maps/place/?q=' + encodeURIComponent(address)
      }

      return link
    },
    dealerAddress() {
      let address = null

      if (this.rawDealerData?.address !== undefined) {
        const d = this.rawDealerData.address
        address =
          d.street + ' ' + d.houseNumber + ', ' + d.zipcode + ' ' + d.city
      }

      return address
    },
    contactByCondition() {
      let contact

      if (
        Array.isArray(this.rawDealerData?.vehicleMarket?.contacts) &&
        this.rawVehicleData
      ) {
        contact = this.rawDealerData.vehicleMarket.contacts.find(contact => {
          return resolveCondition(
            contact.condition,
            this.getInitialSearchParams(),
            this.rawVehicleData
          )
        })
      }

      return contact === undefined ? null : contact
    },
    contactPhoneObject() {
      let obj = null

      const foundByCondition = (
        this.contactByCondition?.communication || []
      ).find(c => c.name === 'phone')

      if (foundByCondition !== undefined) {
        obj = foundByCondition.settings
      }

      // use already existing fixed phone number from dealer object, if no condition can be applied
      if (obj === null && this.rawDealerData?.phones?.fixed !== undefined) {
        const fixed = this.rawDealerData?.phones?.fixed

        obj = {
          countryCode: fixed.countryCallingCode,
          areaCode: fixed.areaCode,
          number: fixed.number
        }
      }

      return obj
    },
    phoneNumber() {
      let str = null

      if (this.contactPhoneObject !== null) {
        const n = this.contactPhoneObject
        str = n.countryCode + '' + n.areaCode + '' + n.number
      }

      return str
    },
    formattedPhoneNumber() {
      let str = null

      if (this.contactPhoneObject !== null) {
        const n = this.contactPhoneObject
        str =
          n.countryCode.replace(/[0]{2}/, '+') +
          ' ' +
          n.areaCode +
          ' ' +
          n.number
      }

      return str
    }
  },
  watch: {
    'googleMaps.loaded'(loaded) {
      if (loaded && this.rawDealerData) {
        this.$nextTick(this.createMap)
      }
    },
    rawDealerData() {
      this.handleInitialMapLoading()
    }
  },
  mounted() {
    this.handleInitialMapLoading()
  },
  methods: {
    ...mapActions('core', {
      loadGoogleMaps: 'loadGoogleMaps'
    }),
    handleInitialMapLoading() {
      if (this.googleMaps.loaded && this.rawDealerData) {
        this.$nextTick(this.createMap)
      } else if (this.appMode === 'widget') {
        if (this.consentAllowedServices.includes('googleMaps')) {
          this.loadGoogleMaps()
        }
      }
    },
    createMap() {
      let lat, lon

      const map = new window.google.maps.Map(this.$refs.map, {
        center: { lat: 0, lng: 0 },
        zoom: 15,
        mapId: 'DEMO_MAP_ID'
      })

      const createMap = (lat, lon) => {
        map.setCenter(new window.google.maps.LatLng(lat, lon))

        new window.google.maps.marker.AdvancedMarkerElement({
          map,
          position: { lat, lng: lon }
        })
      }

      if (
        this.rawDealerData.coordinates.lat === undefined ||
        this.rawDealerData.coordinates.lon === undefined
      ) {
        const d = this.rawDealerData.address
        const address =
          this.rawDealerData.name +
          ',' +
          d.street +
          ' ' +
          d.houseNumber +
          ', ' +
          d.zipcode +
          ' ' +
          d.city

        const request = {
          query: address,
          fields: ['name', 'geometry']
        }

        const service = new window.google.maps.places.PlacesService(map)

        service.findPlaceFromQuery(request, (results, status) => {
          if (
            status === window.google.maps.places.PlacesServiceStatus.OK &&
            results
          ) {
            if (results.length > 0) {
              lat = results[0]?.geometry?.location?.lat()
              lon = results[0]?.geometry?.location?.lng()
              createMap(lat, lon)
            }
          }
        })
      } else {
        lat = this.rawDealerData.coordinates.lat
        lon = this.rawDealerData.coordinates.lon

        createMap(lat, lon)
      }
    },
    onShowMap() {
      if (this.appMode === 'widget') {
        this.loadGoogleMaps()
        window.dispatchEvent(
          new CustomEvent('am-consent-accept-service', {
            detail: {
              service: 'googleMaps'
            }
          })
        )
      } else {
        if (window.UC_UI) {
          const templateId = window.UC_UI.getServicesBaseInfo().find(
            service => service.name === 'Google Maps'
          ).id

          window.UC_UI.acceptService(templateId)
        }
      }
    }
  }
}
</script>
