<template>
  <a
    :href="vehicleUrl"
    class="am-vehicle-tile"
    :class="{ 'am-vehicle-tile--favored': isFavorite }"
    @click.stop.prevent="$emit('view')"
  >
    <div class="am-vehicle-tile__inner">
      <div>
        <div class="am-vehicle-tile__title">{{ title }}</div>
        <button
          class="am-vehicle-tile__fav"
          :aria-label="
            isFavorite
              ? $t('favorites.vehicle.remove')
              : $t('favorites.vehicle.add')
          "
          @click.stop.prevent="$emit('favour')"
        >
          <amui-icon :name="favoriteIconName"></amui-icon>
        </button>
      </div>
      <div class="am-vehicle-tile__usage-type">
        <amui-chip
          v-if="usageType"
          :label="usageType.label"
          :type="usageType.value === 'NEW' ? 'primary' : 'secondary'"
        />
      </div>
      <div class="am-vehicle-tile__inner-2">
        <div class="am-vehicle-tile__image-wrap">
          <amui-image
            :sources="sources"
            :fallback-source="fallbackSource"
            :alt="title"
            ratio="3:2"
            object-fit="contain"
            loading="lazy"
            class="am-vehicle-tile__image"
          />
        </div>
        <div class="am-vehicle-tile__features">
          {{ featureList.join(', ') }}
        </div>
        <div class="am-vehicle-tile__price">
          <div>{{ price }}</div>
          <div v-if="rate" class="am-vehicle-tile__rate">
            {{ $t('price.indications.monthly.from2', { price: rate }) }}
            <div class="am-vehicle-tile__rate-small-print">
              <div v-if="rateDetailsLabel">{{ rateDetailsLabel }}</div>
              <a
                v-if="rateDetailsUrl"
                @click.stop.prevent="$emit('view-rate-details')"
                :href="rateDetailsUrl"
                >Details</a
              >
            </div>
          </div>
        </div>
        <div class="am-vehicle-tile__efficiency">
          <span v-if="efficiencyText"
            >{{ efficiencyText }}
            <sup>{{ efficiencyTextReferenceNumber }}</sup></span
          >
        </div>
      </div>
    </div>
  </a>
</template>

<script>
import { AmuiIcon } from '@/../ui/components/icon'
import { AmuiImage } from '@/../ui/components/image'
import { AmuiChip } from '@/../ui/components/chip'

export default {
  name: 'AmVehicleTile',

  components: {
    AmuiIcon,
    AmuiImage,
    AmuiChip
  },

  props: {
    sources: {
      type: Array,
      required: true,
      validator(sources) {
        return sources.every(
          source => 'media' in source && 'srcset' in source && source.srcset
        )
      }
    },
    fallbackSource: {
      type: String,
      required: false,
      default: null
    },
    vehicleUrl: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    isFavorite: {
      type: Boolean,
      required: false
    },
    price: {
      type: String,
      required: true
    },
    rate: {
      type: String,
      required: false,
      default: null
    },
    rateDetailsLabel: {
      type: String,
      required: false,
      default: null
    },
    rateDetailsUrl: {
      type: String,
      required: false,
      default: null
    },
    features: {
      type: Array,
      required: true,
      default: null
    },
    customerHighlights: {
      type: Array,
      required: false,
      default: () => []
    },
    efficiencyClass: {
      type: String,
      required: false,
      default: null
    },
    efficiencyText: {
      type: String,
      required: false,
      default: null
    },
    efficiencyTextReferenceNumber: {
      type: String,
      default: null
    },
    usageType: {
      type: Object,
      required: false,
      default: null
    }
  },

  computed: {
    favoriteIconName() {
      return this.isFavorite ? 'favorite-filled' : 'favorite'
    },
    featureList() {
      let features = []

      features = features.concat(this.customerHighlights.map(c => c.label))

      const mileage = this.features.find(f => f.id === 'mileage')
      const fuel = this.features.find(f => f.id === 'fuel')

      if (fuel !== undefined) {
        features.unshift(fuel.label)
      }

      if (mileage !== undefined) {
        features.unshift(mileage.label)
      }

      return features
    }
  }
}
</script>

<style lang="scss">
@import './vehicle-tile';
</style>
