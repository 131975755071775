export const resolveCondition = (
  condition,
  initialSearchParams,
  vehicleObject
) => {
  let resp = false

  if (condition.operator) {
    const method = condition.operator === 'and' ? 'every' : 'some'

    if (Array.isArray(condition.conditions)) {
      return condition.conditions[method](c => {
        return resolveCondition(c, initialSearchParams, vehicleObject)
      })
    }
  } else {
    if (condition.condition === 'vehicle') {
      if (initialSearchParams[condition.key] !== undefined) {
        if (Array.isArray(initialSearchParams[condition.key])) {
          resp = vehicleObject[condition.key].includes(condition.value)
        } else if (typeof initialSearchParams[condition.key] === 'string') {
          resp = initialSearchParams[condition.key] === condition.value
        }
      }
    }
  }

  return resp
}

export default {
  resolveCondition
}
