export const onPasteZipCode = event => {
  const originData = event.clipboardData.getData('text')
  const cleanedData = originData.replace(/[^0-9]/g, '')

  if (originData !== cleanedData || originData.length > 5) {
    event.preventDefault()
  }
}

export const onKeyPressZipCode = event => {
  if (
    (event.target.value.length >= 5 &&
      event.target.selectionEnd - event.target.selectionStart === 0) ||
    !['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(event.key)
  ) {
    if (
      !['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight'].includes(event.key) &&
      !(['x', 'c', 'v'].includes(event.key) && event.metaKey === true)
    ) {
      event.preventDefault()
    }
  }
}

export const onPastePhone = event => {
  const originData = event.clipboardData.getData('text')
  const cleanedData = originData.replace(/[^0-9]/g, '')

  if (originData !== cleanedData) {
    event.preventDefault()
  }
}

export const onKeyPressPhone = event => {
  if (!['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(event.key)) {
    if (
      !['Backspace', 'Tab'].includes(event.key) &&
      !(['x', 'c', 'v'].includes(event.key) && event.metaKey === true)
    ) {
      event.preventDefault()
    }
  }
}

export const validateEmail = email => {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ //eslint-disable-line
  return re.test(email)
}

export const isValideZipcode = input => {
  const cleanedInput = input.replace(/[^0-9]/g, '')
  return input.length > 0 && input.length <= 5 && input === cleanedInput
}
