<template>
  <div
    class="amui-input"
    :class="{
      'amui-input--disabled': disabled,
      'amui-input--no-label': !hasLabel,
      'amui-input--focus': focused,
      'amui-input--invalid': invalid,
      'amui-input--responsive': viewport === null
    }"
  >
    <input
      class="amui-input__input"
      :value="value"
      :disabled="disabled"
      :id="componentId"
      :type="type"
      :autocomplete="autocomplete"
      @focus="onFocus"
      @blur="onBlur"
      @input="$emit('input', $event.target.value)"
      @paste="$emit('paste', $event)"
      @keydown="$emit('keydown', $event)"
    />
    <label
      class="amui-input__label"
      :class="
        isLabelSmall ? 'amui-input__small-label' : 'amui-input__large-label'
      "
      :for="componentId"
      v-if="hasLabel"
      >{{ label }}</label
    >
    <amui-icon
      v-if="type === 'date'"
      name="calendar-today"
      class="amui-input__icon"
    />
  </div>
</template>

<script>
import { AmuiIcon } from '@/../ui/components/icon'

export default {
  name: 'AmuiInput',

  components: {
    AmuiIcon
  },

  data() {
    return {
      focused: false,
      componentId: null
    }
  },
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: false,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    invalid: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      required: false,
      default: 'text'
    },
    viewport: {
      type: String,
      default: null,
      validate(value) {
        return ['s'].includes(value)
      }
    },
    autocomplete: {
      type: String,
      required: false,
      default: null
    }
  },
  computed: {
    isLabelSmall() {
      if (this.value !== '' || this.focused || this.type === 'date') {
        return true
      } else {
        return false
      }
    },
    hasLabel() {
      return this.label && this.label.trim() !== '' ? true : false
    }
  },
  methods: {
    onFocus(e) {
      this.focused = true
      this.$emit('focus', e.target.value)
    },
    onBlur(e) {
      this.focused = false
      this.$emit('blur', e.target.value)
    }
  },
  created() {
    this.componentId = 'amui-input-' + this.$am.id.get()
  }
}
</script>
